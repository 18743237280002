@media screen and (min-width: 1225px) {
  .auth-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .auth-logo {
    height: 90px;
    width: 150px;
    margin: 50px 0px;
  }
  
  .auth-content {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(50px);
    max-width: 400px;
    /* width: 600px; */
    padding: 50px;
    /* height: 500px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .auth-subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-top: 10px;
  }
  
  .auth-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;  
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-top: 10px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  
  
  .auth-input {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    margin-top: 20px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 300px;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
          box-sizing: border-box;
  }

  .form-note {
    color: #FFFFFF;
    font-size: 10px;
    margin-top: 6px;
    width: 300px;
  }
  
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  .auth-submit-button {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 6px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 20px;
  }
  
  .auth-text-btn {
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1224px) {
  .auth-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .auth-logo {
    height: 90px;
    width: 150px;
    margin: 50px 0px;
  }
  
  .auth-content {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(50px);
    max-width: 400px;
    /* width: 600px; */
    padding: 50px;
    /* height: 500px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .auth-subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-top: 10px;
  }
  
  .auth-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;  
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-top: 10px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  
  
  .auth-input {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    margin-top: 20px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 300px;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
          box-sizing: border-box;
  }

  .form-note {
    color: #FFFFFF;
    font-size: 10px;
    margin-top: 6px;
    width: 300px;
  }
  
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  .auth-submit-button {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 6px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 20px;
  }
  
  .auth-text-btn {
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 10px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .auth-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .auth-logo {
    height: 90px;
    width: 150px;
    margin: 50px 0px;
  }
  
  .auth-content {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(50px);
    max-width: 350px;
    /* width: 600px; */
    padding: 50px;
    /* height: 500px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .auth-subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-top: 10px;
    text-align: center;
  }
  
  .auth-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;  
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-top: 10px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  
  
  .auth-input {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    margin-top: 20px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 300px;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
          box-sizing: border-box;
  }

  .form-note {
    color: #FFFFFF;
    font-size: 10px;
    margin-top: 6px;
    width: 300px;
  }
  
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  .auth-submit-button {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 6px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 20px;
  }
  
  .auth-text-btn {
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 10px;
  }
}

@media screen and (max-width: 480px) {
  .auth-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .auth-logo {
    height: 90px;
    width: 150px;
    margin: 50px 0px;
  }
  
  .auth-content {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(50px);
    max-width: 300px;
    /* width: 600px; */
    padding: 50px;
    /* height: 500px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .auth-subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-top: 10px;
    text-align: center;
  }
  
  .auth-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;  
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-top: 10px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  
  
  .auth-input {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    height: 50px;
    margin-top: 20px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 250px;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
          box-sizing: border-box;
  }

  .form-note {
    color: #FFFFFF;
    font-size: 10px;
    margin-top: 6px;
    width: 250px;
  }
  
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  .auth-submit-button {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 6px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 20px;
  }
  
  .auth-text-btn {
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 10px;
  }
}

