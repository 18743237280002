@media screen and (min-width: 1025px) {
  .vessel-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 250px;
    right: 10px;
    bottom: 0px;
  }
  
  .user-content {
    background: rgba(0, 0, 0, 0.2);
    /* backdrop-filter: blur(10px); */
    max-width: 400px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .modal-close-button {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;    
    z-index: 999;
  }

  .modal-close-button > img {
    width: 40px;
  }
  
  .cert-desc {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);        
    font-size: 18px;
    color: #FFFFFF;
    padding: 10px;
    height: 120px;
    resize: none;
    width: 300px;
    margin-top: 20px;
    align-self: center;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .file-input-label-2 {
    /* text-align:center;     */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: thin dashed #FFFFFF;
    width: 300px;
    margin-top: 20px;
    height: 80px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);   
    cursor:pointer;
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: underline;  
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .file-input-label-2-span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .file-upload-2 {
    width: 20px;
    margin-right: 15px;
  }

  #cert_img_name {
    margin-top: -10px;
  }

  .cert-decription {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    margin-left: 15px;
    text-align: center;
  }

  .cert-decription-head {
    text-align: center;
  }
}

  

@media screen and (min-width: 481px) and (max-width: 1024px)  {
  .vessel-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 100px;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }
  
  .user-content {
    background: rgba(0, 0, 0, 0.2);
    /* backdrop-filter: blur(50px); */
    max-width: 400px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .modal-close-button {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;    
    z-index: 999;
  }

  .modal-close-button > img {
    width: 40px;
  }

  .cert-desc {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);        
    font-size: 18px;
    color: #FFFFFF;
    padding: 10px;
    height: 120px;
    resize: none;
    width: 300px;
    margin-top: 20px;
    align-self: center;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .file-input-label-2 {
    /* text-align:center;     */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: thin dashed #FFFFFF;
    width: 300px;
    margin-top: 20px;
    height: 80px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);   
    cursor:pointer;
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: underline;  
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .file-input-label-2-span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .file-upload-2 {
    width: 20px;
    margin-right: 15px;
  }

  #cert_img_name {
    margin-top: -10px;
  }

  .cert-decription {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    margin-left: 15px;
    text-align: center;
  }

  .cert-decription-head {
    text-align: center;
  }
}

@media screen and (max-width: 480px)  {
  .vessel-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 100px;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }
  
  .user-content {
    background: rgba(0, 0, 0, 0.2);
    /* backdrop-filter: blusr(50px); */
    max-width: 400px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .modal-close-button {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;    
    z-index: 999;
  }

  .modal-close-button > img {
    width: 50px;
  }

  .cert-desc {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);        
    font-size: 18px;
    color: #FFFFFF;
    padding: 10px;
    height: 120px;
    resize: none;
    width: 250px;
    margin-top: 20px;
    align-self: center;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }
  

  .file-input-label-2 {
    /* text-align:center;     */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: thin dashed #FFFFFF;
    width: 250px;
    margin-top: 20px;
    height: 80px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);   
    cursor:pointer;
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: underline;  
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .file-input-label-2-span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .file-upload-2 {
    width: 20px;
    margin-right: 15px;
  }

  #cert_img_name {
    margin-top: -10px;
  }

  .cert-decription {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    margin-left: 15px;
    text-align: center;
  }

  .cert-decription-head {
    text-align: center;
  }
}

.vessel-modal:focus {outline:0;}

.cert-img {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  object-fit: contain;   
}