@media screen and (min-width: 1225px) {
  .mailing-list-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }
  .mailing-list-content {
    flex: 1;
    padding-left: 350px;
    padding-right: 100px;
    padding-top: 50px;
  }

  .mailing-list-title {
    font-size: 32px;
    font-weight: 600;
    color: white;
  }

  .mailing-lists-grid {
    display: grid;
    grid-template-columns: auto auto auto; 
    /* column-gap: 40px; */
    row-gap: 40px;
    width: 100%;
    margin-top: 50px;
  }

  .mailing-lists-card {
    max-width: 320px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 6px;
    cursor: pointer;
    padding: 40px 20px;
    text-decoration: none;
  }

  .mailing-lists-card:hover {
    background: rgba(0, 0, 0, 0.18);
    text-decoration: none;
  }

  .mailing-lists-card-icon {
    width: 60px;
  }

  .mailing-lists-card-title {
    font-size: 18px;
    font-weight: 500;
    color: white;
    margin-top: 10px;
  }

  .mailing-lists-card-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: white;
    margin-top: 6px;
  }

  .unsubcribed-clients-content {
    background: rgba(255, 255, 255, 0.2);
    /* backdrop-filter: blur(10px); */
    width: 850px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1224px) {
  .mailing-list-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .mailing-list-content {
    flex: 1;
    padding-left: 300px;
    padding-right: 50px;
    padding-top: 50px;
  }


  .mailing-list-title {
    font-size: 28px;
    font-weight: 600;
    color: white;
  }

  .mailing-lists-grid {
    display: grid;
    grid-template-columns: auto auto auto; 
    /* column-gap: 40px; */
    row-gap: 40px;
    width: 100%;
    margin-top: 50px;
  }

  .mailing-lists-card {
    max-width: 320px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 6px;
    cursor: pointer;
    padding: 40px 20px;
    text-decoration: none;
  }

  .mailing-lists-card:hover {
    background: rgba(0, 0, 0, 0.18);
    text-decoration: none;
  }

  .mailing-lists-card-icon {
    width: 60px;
  }

  .mailing-lists-card-title {
    font-size: 18px;
    font-weight: 500;
    color: white;
    margin-top: 10px;
  }

  .mailing-lists-card-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: white;
    margin-top: 6px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .mailing-list-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .mailing-list-content {
    flex: 1;
    padding: 10px 20px;
    margin-top: 120px;
  }


  .mailing-list-title {
    font-size: 24px;
    font-weight: 600;
    color: white;
  }

  .mailing-lists-grid {
    display: grid;
    grid-template-columns: auto auto; 
    /* column-gap: 40px; */
    row-gap: 40px;
    width: 100%;
    margin-top: 50px;
  }

  .mailing-lists-card {
    max-width: 320px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 6px;
    cursor: pointer;
    padding: 40px 20px;
    text-decoration: none;
  }

  .mailing-lists-card:hover {
    background: rgba(0, 0, 0, 0.18);
    text-decoration: none;
  }

  .mailing-lists-card-icon {
    width: 60px;
  }

  .mailing-lists-card-title {
    font-size: 18px;
    font-weight: 500;
    color: white;
    margin-top: 10px;
  }

  .mailing-lists-card-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: white;
    margin-top: 6px;
  }
}

@media screen and (max-width: 480px) {
  .mailing-list-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }

  .mailing-list-content {
    flex: 1;
    padding: 10px 20px;
    margin-top: 100px;
  }


  .mailing-list-title {
    font-size: 24px;
    font-weight: 600;
    color: white;
  }

  .mailing-lists-grid {
    display: grid;
    grid-template-columns: auto; 
    /* column-gap: 40px; */
    row-gap: 40px;
    width: 100%;
    margin-top: 50px;
  }

  .mailing-lists-card {
    /* max-width: 320px; */
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 6px;
    cursor: pointer;
    padding: 40px 20px;
    text-decoration: none;
  }

  .mailing-lists-card:hover {
    background: rgba(0, 0, 0, 0.18);
    text-decoration: none;
  }

  .mailing-lists-card-icon {
    width: 60px;
  }

  .mailing-lists-card-title {
    font-size: 18px;
    font-weight: 500;
    color: white;
    margin-top: 10px;
  }

  .mailing-lists-card-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: white;
    margin-top: 6px;
  }
}

.td-row {
  width: 100%;
  display: table-row;
}

.boxsizingBorder {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}