.App {
  background-image: url('./assets/images/bg_1.png');
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw ;
  overflow-x: hidden;
  overflow: hidden;
  overflow-y: hidden;
}

input:focus {
  background: rgba(255, 255, 255, 0.3);
}