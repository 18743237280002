@media screen and (min-width: 1225px) {
  .veessels-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }
  
  .veessels-content {
    flex: 1;
    padding-left: 350px;
    padding-right: 100px;
    padding-top: 50px;
  }
  
  .top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    width: 593px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    /* padding: 10px; */
  }
  
  .search-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  .search-input {
    font-size: 18px;
    height: 50px;
    width: 593px;
    padding-left: 50px;
    background: rgba(0, 0, 0, 0);
    color: #FFFFFF;
    border: 0px;
    -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
            box-sizing: border-box;
  }
  
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  .search-input:focus {
    background: rgba(255, 255, 255, 0);
    border: 0px;
  }
  
  .add-new {
    width: 155px;
    height: 50px;
    background: rgba(0, 146, 65, 0.75);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    text-decoration: none;
  }
  
  .table {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    /* border-top-left-radius: 20px;
    border-top-right-radius: 20px; */
    width: 100%;
    border-collapse: collapse;  
  }
  
  .thead {
    height: 45px;  
    background: rgba(255, 255, 255, 0.8);
    position: sticky;
    top: 0;
  }
  
  .th {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #000;
    text-align: left;
  }
  
  .tbody {
    height: auto;
    background: rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .tr {
    height: 40px;
    border-bottom: 0.5px solid #FFFFFF;  
    cursor: pointer;
  }
  
  .td {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #FFFFFF;
    min-width: 150px;
  }
  
  .fixTableHead {
    overflow-y: auto;
    height: 520px;
    padding-right: 17px;
    margin-right: -17px;
  }

  .add-vessels-section {
    flex: 1;
    padding-left: 350px;
    padding-right: 100px;
    padding-top: 50px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .add-vessel-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    text-align: center;
  }


  .vessel-form {
    align-items: flex-start;
    width: 100%;
  }

  .add-vessel-subtitle {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    text-align: left;
    margin-left: 20px;
  }

  .vessels-input-fields {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
    row-gap: 20px;
    width: 100%;
    margin-top: 20px;
  }


  .vessel-input {
    flex: 1;
    margin: 0px 20px;
  }

  /* select {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    height: 40px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
  } */

  .vessel-label {
    font-size: 14px;
    color: #FFFFFF;
    margin-bottom: 6px;
  }

  .vessel-input-field {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    height: 40px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
         box-sizing: border-box;
  }

  /* .vessel-description-input-field {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    height: 40px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
         box-sizing: border-box;
  } */

  textarea {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);        
    font-size: 18px;
    color: #FFFFFF;
    padding: 10px;
    height: 120px;
    resize: none;
    width: calc(100% - 15px);
    /* margin-left: 20px; */
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .vessel-description-input {
    margin-top: 20px;
    width: calc(100% - 30px);
    margin-left: 20px;
    display: block;
  }

  .file-input-label {
    text-align:center;
    border: thin dashed #FFFFFF;
    margin: 30px 20px;
    width: calc(100% - 40px);
    height: auto;
    padding: 15px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);   
    cursor:pointer;
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: underline;  
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .file-upload {
    width: 50px;
  }

  .file-input {
    display: none;    
  }

  #imageName{
    color: #FFFFFF;
  }


  .submit-button {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 4px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
  }

}

@media screen and (min-width: 1025px) and (max-width: 1224px) {
  .veessels-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }
  
  .veessels-content {
    flex: 1;
    padding-left: 300px;
    padding-right: 50px;
    padding-top: 50px;
  }
  
  .top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    width: 350px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    /* padding: 10px; */
  }
  
  .search-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  .search-input {
    font-size: 18px;
    height: 40px;
    width: 350px;
    padding-left: 50px;
    background: rgba(0, 0, 0, 0);
    color: #FFFFFF;
    border: 0px;
    -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
            box-sizing: border-box;
  }
  
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  .search-input:focus {
    background: rgba(255, 255, 255, 0);
    border: 0px;
  }
  
  .add-new {
    width: 155px;
    height: 40px;
    background: rgba(0, 146, 65, 0.75);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    text-decoration: none;
  }
  
  .table {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    /* border-top-left-radius: 20px;
    border-top-right-radius: 20px; */
    width: 100%;
    border-collapse: collapse;  
  }
  
  .thead {
    height: 45px;  
    background: rgba(255, 255, 255, 0.8);
    position: sticky;
    top: 0;
  }
  
  .th {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #000;
    text-align: left;
  }
  
  .tbody {
    height: auto;
    background: rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .tr {
    height: 40px;
    border-bottom: 0.5px solid #FFFFFF;  
    cursor: pointer;
  }
  
  .td {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #FFFFFF;
    min-width: 150px;
  }
  
  .fixTableHead {
    overflow-y: auto;
    height: 520px;
    padding-right: 17px;
    margin-right: -17px;
  }


  .add-vessels-section {
    flex: 1;
    padding-left: 300px;
    padding-right: 50px;
    padding-top: 50px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .add-vessel-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    text-align: center;
  }


  .vessel-form {
    align-items: flex-start;
    width: 100%;
  }

  .add-vessel-subtitle {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    text-align: left;
    margin-left: 20px;
  }

  .vessels-input-fields {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
    row-gap: 20px;
    width: 100%;
    margin-top: 20px;
  }


  .vessel-input {
    flex: 1;
    margin: 0px 20px;
  }

  .vessel-label {
    font-size: 14px;
    color: #FFFFFF;
    margin-bottom: 6px;
  }

  .vessel-input-field {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    height: 40px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
         box-sizing: border-box;
  }

  /* .vessel-description-input-field {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    height: 40px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
         box-sizing: border-box;
  } */

  textarea {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);        
    font-size: 18px;
    color: #FFFFFF;
    padding: 10px;
    height: 120px;
    resize: none;
    width: calc(100% - 15px);
    /* margin-left: 20px; */
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .vessel-description-input {
    margin-top: 20px;
    width: 97%;
    margin-left: 20px;
  }

  .file-input-label {
    text-align:center;
    border: thin dashed #FFFFFF;
    margin: 30px 20px;
    width: calc(100% - 40px);
    height: auto;
    padding: 15px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);   
    cursor:pointer;
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: underline;  
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .file-upload {
    width: 50px;
  }

  .file-input {
    display: none;    
  }

  #imageName{
    color: #FFFFFF;
  }


  .submit-button {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 4px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .veessels-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }
  
  .veessels-content {
    flex: 1;
    padding: 10px 20px;
    margin-top: 120px;
  }
  
  .top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    width: 300px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    /* padding: 10px; */
  }
  
  .search-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  .search-input {
    font-size: 18px;
    height: 40px;
    width: 300px;
    padding-left: 50px;
    background: rgba(0, 0, 0, 0);
    color: #FFFFFF;
    border: 0px;
    -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
            box-sizing: border-box;
  }
  
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  .search-input:focus {
    background: rgba(255, 255, 255, 0);
    border: 0px;
  }
  
  .add-new {
    width: 155px;
    height: 40px;
    background: rgba(0, 146, 65, 0.75);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    text-decoration: none;
  }
  
  .table {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    /* border-top-left-radius: 20px;
    border-top-right-radius: 20px; */
    width: 100%;
    border-collapse: collapse;  
  }
  
  .thead {
    height: 45px;  
    background: rgba(255, 255, 255, 0.8);
    position: sticky;
    top: 0;
  }
  
  .th {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #000;
    text-align: left;
  }
  
  .tbody {
    height: auto;
    background: rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .tr {
    height: 40px;
    border-bottom: 0.5px solid #FFFFFF;  
    cursor: pointer;
  }
  
  .td {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #FFFFFF;
    min-width: 150px;
  }
  
  .fixTableHead {
    overflow-y: auto;
    height: 520px;
    padding-right: 17px;
    margin-right: -17px;
  }


  .add-vessels-section {
    flex: 1;
    padding: 10px 20px;
    margin-top: 100px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .add-vessel-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    text-align: center;
  }


  .vessel-form {
    align-items: flex-start;
    width: 100%;
  }

  .add-vessel-subtitle {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    text-align: left;
    margin-left: 20px;
  }

  .vessels-input-fields {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
    row-gap: 20px;
    width: 100%;
    margin-top: 20px;
  }


  .vessel-input {
    flex: 1;
    margin: 0px 20px;
  }

  .vessel-label {
    font-size: 14px;
    color: #FFFFFF;
    margin-bottom: 6px;
  }

  .vessel-input-field {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    height: 40px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
         box-sizing: border-box;
  }

  /* .vessel-description-input-field {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    height: 40px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
         box-sizing: border-box;
  } */

  textarea {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);        
    font-size: 18px;
    color: #FFFFFF;
    padding: 10px;
    height: 120px;
    resize: none;
    width: calc(100% - 15px);
    /* margin-left: 20px; */
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .vessel-description-input {
    margin-top: 20px;
    width: 97%;
    margin-left: 20px;
  }

  .file-input-label {
    text-align:center;
    border: thin dashed #FFFFFF;
    margin: 30px 20px;
    width: calc(100% - 40px);
    height: auto;
    padding: 15px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);   
    cursor:pointer;
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: underline;  
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .file-upload {
    width: 50px;
  }

  .file-input {
    display: none;    
  }

  #imageName{
    color: #FFFFFF;
  }


  .submit-button {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 4px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 480px) {
  .veessels-container {
    background-image: url('../../assets/images/bg_4.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    display: flex;
    flex-direction: column;
  }
  
  .veessels-content {
    flex: 1;
    padding: 10px 20px;
    margin-top: 100px;
  }
  
  .top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    width: 200px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    /* padding: 10px; */
  }
  
  .search-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  .search-input {
    font-size: 18px;
    height: 40px;
    width: 200px;
    padding-left: 50px;
    background: rgba(0, 0, 0, 0);
    color: #FFFFFF;
    border: 0px;
    -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
            box-sizing: border-box;
  }
  
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  
  .search-input:focus {
    background: rgba(255, 255, 255, 0);
    border: 0px;
  }
  
  .add-new {
    width: 90px;
    height: 40px;
    background: rgba(0, 146, 65, 0.75);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    text-decoration: none;
  }
  
  .table {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    /* border-top-left-radius: 20px;
    border-top-right-radius: 20px; */
    width: 100%;
    border-collapse: collapse;  
  }
  
  .thead {
    height: 45px;  
    background: rgba(255, 255, 255, 0.8);
    position: sticky;
    top: 0;
  }
  
  .th {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #000;
    text-align: left;
  }
  
  .tbody {
    height: auto;
    background: rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .tr {
    height: 40px;
    border-bottom: 0.5px solid #FFFFFF;  
    cursor: pointer;
  }
  
  .td {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #FFFFFF;
    min-width: 100px;
  }
  
  .fixTableHead {
    overflow-y: auto;
    height: 620px;
    padding-right: 17px;
    margin-right: -17px;
  }

  .add-vessels-section {
    flex: 1;
    padding: 10px 20px;
    margin-top: 100px;
    height: auto;
    overflow-y: scroll;
    overflow: scroll;
    overflow-x: hidden;
  }

  .add-vessel-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    text-align: center;
  }


  .vessel-form {
    align-items: flex-start;
    width: 100%;
  }

  .add-vessel-subtitle {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    text-align: left;
    margin-left: 20px;
  }

  .vessels-input-fields {
    display: grid;
    grid-template-columns: auto;
    /* column-gap: 20px; */
    row-gap: 20px;
    width: 100%;
    margin-top: 20px;
  }


  .vessel-input {
    flex: 1;
    margin: 0px 20px;
  }

  .vessel-label {
    font-size: 14px;
    color: #FFFFFF;
    margin-bottom: 6px;
  }

  .vessel-input-field {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    height: 40px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
         box-sizing: border-box;
  }

  /* .vessel-description-input-field {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    height: 40px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0px 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
         box-sizing: border-box;
  } */

  textarea {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);        
    font-size: 18px;
    color: #FFFFFF;
    padding: 10px;
    height: 120px;
    resize: none;
    width: calc(100% - 15px);
    /* margin-left: 20px; */
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .vessel-description-input {
    margin-top: 20px;
    width: calc(100% - 28px);
    margin-left: 20px;
  }

  .file-input-label {
    text-align:center;
    border: thin dashed #FFFFFF;
    margin: 30px 20px;
    width: calc(100% - 40px);
    height: auto;
    padding: 15px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);   
    cursor:pointer;
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: underline;  
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

  .file-upload {
    width: 50px;
  }

  .file-input {
    display: none;    
  }

  #imageName{
    color: #FFFFFF;
  }


  .submit-button {
    width: 236px;
    height: 45px;    
    background: rgba(0, 146, 65, 0.85);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.145em;
    color: #FFFFFF;
    border-radius: 4px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
  }
}

.boxsizingBorder {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

.delete_item {
  opacity: 0;
  height: 0px;
  transition: all 0.5s ease-in-out;
}

.td-row {
  width: 100%;
  display: table-row;
}