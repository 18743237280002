@media screen and (min-width: 1025px) {
  .mobile-nav-bar {
    display: none;
  }
  .nav-bar {
    height: 100vh;
    width: 250px;
    position: absolute;
    z-index: 99999999999;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    display: flex; 
    flex-direction: column;
  }

  .nav-bar-logo-tagline {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding: 0px 50px;
  }

  .logo {    
    height: 90px;
    text-align: center;
    justify-self: center;
    /* margin-bottom: 10px; */
  }

  .nav-bar-logo-tagline > p {
    color: white;
    font-size: 18px;
    font-family: 'Great Vibes';
    margin-top: 0px;
  }

  .nav-list {
    flex: 1;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 0px 50px;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
  }

  .nav-item {
    padding: 5px 0px;
  }

  .nav-link {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 16px;    
  }

  .nav-link-active {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 16px;
    font-weight: 800;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding-bottom: 2px;
  }

  .nav-item-2 {
    padding: 5px 0px;    
  }

  .nav-link-2 {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 16px;  
  }
  /*  For mobile
  .sub-menu {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(100px);       
  } */

  .sub-menu {
    position: absolute;
    border: 1px solid #fff;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(100px);    
    /* margin: 100px, */
  }

  .sub-item {
    padding: 5px 5px;
    border-bottom: 1px solid #fff;
  }

  .sub-item-link {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 12px;
    font-weight: 500;
  }

  .nav-footer {
    margin: 20px 0px;
    padding: 0px 50px;
  }

  .nav-socials {
    display: flex;
    align-items: center;
  }

  .nav-socials-icon-img {
    height: 20px;
    margin-right: 15px;
  }

  .nav-footer > p {
    font-size: 10px;
    color: #fff;

  }

  .nav-footer > a {
    font-size: 10px;
    color: #fff;
    font-weight: 900;
    text-decoration: none;
  }


  .log-out-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 15px 0px;
  }

  .log-out-icon {
    width: 25px;
  }

  .log-out-btn > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
    margin-left: 15px;
  }
}



@media screen and (min-width: 481px) and (max-width: 1024px) {
  .nav-bar{
    display: none;
  }

  .mobile-nav{
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 9;
  }

  .mobile-nav-bar {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-bottom: 0.5px solid white;
    padding: 15px 20px;
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 0;
    right: 0;
  }

  .mobile-logo {    
    height: 50px;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .menu-icon-img {
    width: 30px;
  }

  .mobile-nav-bar-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .mobile-nav-drawer {
    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: 99999999999;
    top: 0px;
    right: 0px;      
    display: flex; 
    flex-direction: row;   
  }

  .mobile-nav-drawer-open {
    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: 99999999999;
    top: 0px;
    right: 0px;      
    display: flex; 
    flex-direction: row;   
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }

  .mobile-nav-drawer-close {
    right: -100%;
    opacity: 0;
    transition: all 0.5s ease-in-out;

    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: 99999999999;
    top: 0px;
    display: flex; 
    flex-direction: row;   
  }

  .mobile-nav-drawer-overlay {
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(50px);  
    flex: 1;
    height: 100vh;
    cursor: pointer;
  }

  .mobile-nav-drawer-content {
    width: 250px;
    /* height: 100vh; */
    /* background: #19242C;   */
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(70px);
    padding: 20px;
    overflow: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }

  .mobile-drawer-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .mobile-nav-list {
    flex: 1;
    list-style-type: none;
    text-align: right;
    padding: 20px 0px;
  }

  .nav-item {
    padding: 5px 0px;
  }

  .nav-link {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 16px;    
  }

  .nav-link-active {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 16px;
    font-weight: 800;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding-bottom: 2px;
  }

  .nav-item-2 {
    padding: 5px 0px;    
  }

  .nav-link-2 {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 16px;  
  }
  /*  For mobile
  .sub-menu {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(100px);       
  } */

  /* .sub-menu {
    position: absolute;
    border: 1px solid #fff;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(100px);    
  } */

  .sub-item {
    padding: 10px 20px;
    background-color: #12191E;
    margin: 0px -20px;
  }

  .sub-item-link {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 12px;
    font-weight: 500;
  }

  .mobile-nav-socials {
    display: flex;
    align-items: center;
    justify-content: c;
  }

  .nav-socials-icon-img {
    height: 20px;
    margin-left: 15px;
  }

  .log-out-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    padding: 15px 0px;
  }

  .log-out-icon {
    width: 25px;
  }

  .log-out-btn > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
    margin-left: 15px;
  }
  
}


@media screen and (max-width: 480px)  {
  .nav-bar{
    display: none;
  }

  .mobile-nav{
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 9;    
  }

  .mobile-nav-bar {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    padding: 15px 20px;
    position: absolute;
    z-index: 99999;
    top: 0px;
    left: 0px;
    right: 0px;
    border-bottom: 0.5px solid white;
    /* background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(20px) */
  }

  .mobile-logo {    
    height: 50px;
  }

  .menu-icon {
    display: block;
    /* cursor: pointer; */
  }

  .menu-icon-img {
    width: 30px;
  }

  .mobile-nav-bar-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .mobile-nav-drawer-open {
    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: 99999999999;
    top: 0px;
    right: 0px;      
    display: flex; 
    flex-direction: row;   
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }

  .mobile-nav-drawer-close {
    right: -100%;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    position: absolute;
    top: 0px;

    /* height: 100vh;
    width: 100%;
    position: absolute;
    z-index: 99999999999;
    top: 0px;
    display: flex; 
    flex-direction: row;    */
  }

  .mobile-nav-drawer-overlay {
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(50px);   
    /* background: #19242C; */
    flex: 1;
    height: 100vh;
    /* cursor: pointer; */
  }

  .mobile-nav-drawer-content {
    width: 250px;
    /* height: 100vh; */
    /* background: #19242C; */
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(70px);
    padding: 20px;
    overflow: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }

  .mobile-drawer-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .mobile-nav-list {
    flex: 1;
    list-style-type: none;
    text-align: right;
    margin: 40px 0px;
  }

  .nav-item {
    padding: 5px 0px;
  }

  .nav-link {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 18px;    
  }

  .nav-link-active {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 16px;
    font-weight: 800;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding-bottom: 2px;
  }

  .nav-item-2 {
    padding: 5px 0px;    
  }

  .nav-link-2 {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 18px;  
  }
  /*  For mobile
  .sub-menu {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(100px);       
  } */

  /* .sub-menu {
    position: absolute;
    border: 1px solid #fff;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(100px);    
  } */

  .sub-item {
    padding: 10px 20px;
    background-color: #12191E;
    margin: 0px -20px;
  }

  .sub-item-link {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 12px;
    font-weight: 500;
  }

  .mobile-nav-socials {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .nav-socials-icon-img {
    height: 20px;
    margin-left: 15px;
  }

  .log-out-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    padding: 15px 0px;
  }

  .log-out-icon {
    width: 25px;
  }

  .log-out-btn > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
    margin-left: 15px;
  }
}